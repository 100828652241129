import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { 
  Box, 
  IconButton, 
  Stack, 
  Typography, 
  MenuItem, 
  MenuList,
  CircularProgress,
  Switch,
  Tooltip
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import CustomTable from '../Common/CustomTable';
import CustomMenu from '../Common/CustomMenu';

const VariableSetList = function ({ 
  variableSetList, 
  pageNumber, 
  onDelete, 
  isLoading,
  onUpdatePrimaryEnvironment
}) {
  const history = useHistory();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteVariableSetId, setDeleteVariableSetId] = useState('');
  const [anchorEls, setAnchorEls] = useState({});

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const onViewVariableSet = async (variableSetId) => {
    history.push(`/variable-set/${variableSetId}/?page=${pageNumber}`);
  };

  const onClickDelete = (variableSetId) => {
    // Check if trying to delete the last primary environment
    const isPrimaryEnvironment = variableSetList.find(vs => vs.id === variableSetId)?.is_primary;
    const hasOtherEnvironments = variableSetList.length > 1;
    
    if (isPrimaryEnvironment && !hasOtherEnvironments) {
      // Allow deletion of the last primary environment
      setDeleteVariableSetId(variableSetId);
      onToggleDeleteDialog();
    } else if (isPrimaryEnvironment) {
      // Show warning that a new primary will be selected
      setDeleteVariableSetId(variableSetId);
      onToggleDeleteDialog();
    } else {
      // Normal deletion
      setDeleteVariableSetId(variableSetId);
      onToggleDeleteDialog();
    }
  };

  const onDeleteConfirm = () => {
    onDelete(deleteVariableSetId);
    onToggleDeleteDialog();
  };

  const onToggleDeleteDialog = () => {
    setDeleteDialogOpen((prevState) => !prevState);
    if (deleteVariableSetId) setDeleteVariableSetId('');
  };

  const handlePrimaryToggle = (id, currentValue) => {
    // Only allow toggling if it's not already primary
    if (!currentValue) {
      onUpdatePrimaryEnvironment(id);
    }
  };


  const formatDate = (timestamp) => {
    // Convert Unix timestamp (seconds) to milliseconds
    const date = new Date(timestamp * 1000);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const renderTable = () => {
    if (isLoading) {
      return (
        <Box 
          sx={{
            width: "100%", 
            textAlign: "center", 
            padding: "50px 32px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px'
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    if (variableSetList.length === 0) {
      return (
        <Box 
          sx={{
            width: "100%", 
            textAlign: "center", 
            padding: "50px 32px",
            minHeight: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="h6">No results found.</Typography>
        </Box>
      );
    }

    const sxTh = {height: "54px"};

    const headers = [
      {
        id: 'title',
        type: 'text',
        text: 'Title',
        sx: sxTh
      },
      {
        id: 'created_at',
        type: 'text',
        text: 'Created At',
        sx: sxTh
      },
      {
        id: 'updated_at',
        type: 'text',
        text: 'Updated At',
        sx: sxTh
      }
    ];

    // Only show primary column for environments
    if (variableSetList.some(item => item.is_environment)) {
      headers.push({
        id: 'primary',
        type: 'text',
        text: 'Primary',
        align: 'center',
        sx: sxTh
      });
    }

    headers.push({
      id: 'actions',
      type: 'text',
      text: 'Actions',
      align: 'center',
      sx: sxTh
    });

    return (
      <CustomTable
        headers={headers}
        rows={variableSetList.map(({ id, title, is_environment, created_at, updated_at, is_primary }) => {
          const cells = [
            {
              id: 'title-' + id,
              type: 'component',
              content: (
                <Stack direction="column" className="title">
                  <Typography variant="body1" className="truncate-1-lines" title={title}>
                    {title}
                  </Typography>
                </Stack>
              ),
              sx: { cursor: 'pointer', width: "300px" },
              onClick: () => {
                onViewVariableSet(id);
              },
            },
            {
              id: 'created_at-' + id,
              type: 'component',
              content: (
                <Typography variant="body2">
                  {formatDate(created_at)}
                </Typography>
              ),
              sx: { width: "200px" }
            },
            {
              id: 'updated_at-' + id,
              type: 'component',
              content: (
                <Typography variant="body2">
                  {formatDate(updated_at)}
                </Typography>
              ),
              sx: { width: "200px" }
            }
          ];

          // Add Primary switch for environments
          if (is_environment) {
            cells.push({
              id: 'primary-' + id,
              type: 'component',
              content: (
                <Tooltip title={is_primary ? "Primary environment" : "Make primary"}>
                  <Switch
                    checked={is_primary}
                    onChange={() => handlePrimaryToggle(id, is_primary)}
                    color="primary"
                    disabled={is_primary}
                  />
                </Tooltip>
              ),
              align: 'center',
              sx: { width: "100px" }
            });
          }

          cells.push({
            id: 'actions-' + id,
            type: 'component',
            content: (
              <>
                <IconButton
                  id={`actions-btn-${id}`}
                  aria-label="actions"
                  onClick={(e) => handleClick(e, id)}
                >
                  <MoreVert />
                </IconButton>

                <CustomMenu
                  labelledBy={'actions-btn-' + id}
                  anchorEl={anchorEls[id]}
                  onClose={() => handleClose(id)}
                >
                  <MenuList sx={{ minWidth: '100px' }}>
                    <MenuItem
                      onClick={() => {
                        onClickDelete(id);
                        handleClose(id);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </CustomMenu>
              </>
            ),
            align: 'center',
            sx: { width: "50px" }
          });

          return {
            id,
            cells
          };
        })}
        sx={{}}
      />
    );
  };

  const getDeleteDialogContent = () => {
    const isPrimaryEnvironment = variableSetList.find(vs => vs.id === deleteVariableSetId)?.is_primary;
    const hasOtherEnvironments = variableSetList.length > 1;

    if (isPrimaryEnvironment && hasOtherEnvironments) {
      return "This is the primary environment. Deleting it will automatically set another environment as primary. Are you sure you want to delete?";
    }
    return "Are you sure you want to delete?";
  };

  const renderConfirmDialog = () => {
    return (
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={onToggleDeleteDialog}
        onConfirm={onDeleteConfirm}
        title="Confirm Delete"
        content={getDeleteDialogContent()}
      />
    );
  };

  return (
    <>
      <Box>{renderTable()}</Box>
      {deleteDialogOpen && renderConfirmDialog()}
    </>
  );
};

export default VariableSetList;