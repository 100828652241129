import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiAgent } from '../../config/axiosConfig';

export const getMemoryList = createAsyncThunk(
    'getMemoryList',
    async (body, { dispatch, rejectWithValue }) => {
      try {
        const projectId = body.projectId;
        const res = await apiAgent({
          method: 'GET',
          url: `/api/v1/project_messages/`,
          params: { project_id: projectId, page: body.pageNumber, per_page: body.pageSize }
        });
        if (res.status === 200) {
          return res.data;
        } else {
          return rejectWithValue(res.data);
        }
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);


export const createMemory = createAsyncThunk(
    'createMemory',
    async (body, { dispatch, rejectWithValue }) => {
      try {
        const res = await apiAgent({
          method: 'POST',
          url: `/api/v1/project_messages`,
          params: { project_id: body.projectId, message: body.message, environment_set_id: body.environment_set_id }
        });
        if (res.status === 200) {
          return res.data;
        } else {
          return rejectWithValue(res.data);
        }
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const updateMemory = createAsyncThunk(
    'updateMemory',
    async (body, { dispatch, rejectWithValue }) => {
        const data = {};
        if (body.message) data.message = body.message;
      try {
        const res = await apiAgent({
          method: 'PUT',
          url: `/api/v1/project_messages/${body.memoryId}`,
          data: data
        });
        if (res.status === 200) {
          return res.data;
        } else {
          return rejectWithValue(res.data);
        }
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);



export const deleteMemory = createAsyncThunk(
    'deleteMemory',
    async (body, { dispatch, rejectWithValue }) => {
      try {
        const res = await apiAgent({
          method: 'DELETE',
          url: `/api/v1/project_messages/${body.memoryId}`,
        });
        if (res.status === 200) {
          return res.data;
        } else {
          return rejectWithValue(res.data);
        }
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);
