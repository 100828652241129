import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Add } from "@mui/icons-material";
import MainLayout from "layouts/MainLayout.jsx";
import MemoryList from "./MemoryList.jsx";
import CustomButton from "../CustomButton.jsx";
import MemoryModal from "./MemoryModal.jsx";
import { useSnackbar } from "contexts/CustomSnackbarContext.js";
import { getMemoryList, createMemory, updateMemory, deleteMemory } from "redux-store/memoryReducers/memoryActions.js";

const MemoryPage = ({ projectId }) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const isLoading = useSelector(state => state.memory.isLoading);
  const totalPages = useSelector(state => state.memory.totalPages);
  const total = useSelector(state => state.memory.total);
  const [pageNumber, setPageNumber] = useState(1);
  const [actionType, setActionType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionableData, setActionableData] = useState({});

  useEffect(() => {
    console.log("projectId", projectId);
    if (projectId) {
      getMemories();
    }
  }, [pageNumber, projectId]);

  const handleCreateMemory = () => {
    setActionableData({});
    setActionType("create");
    setIsModalOpen(true);
  }

  const handleDelete = async (id) => {
    const response = await dispatch(deleteMemory({ memoryId: id }));
    if (response?.payload && !response?.error) {
      openSnackbar({ message: "Memory deleted successfully.", severity: 'success' });
      getMemories(pageNumber);
    } else {
      const errorMsg = response?.payload?.error;
      openSnackbar({ message: errorMsg || "Failed to delete memory!", severity: 'error' });
    }
  };

  const handleAction = (actionType, data) => {
    if (actionType === "delete") {
      handleDelete(data.id);
    } else {
      setActionType(actionType);
      setActionableData({ ...data });
      setIsModalOpen(true);
    }
  }

  const handleConfirm = async (actionType, data) => {
    if (actionType === "edit") {
      const response = await dispatch(updateMemory({ message: data.message, environment_set_id: data.environment_set_id, memoryId: actionableData.id }));
      if (response?.payload) {
        openSnackbar({ message: "Memory updated successfully.", severity: 'success' });
        handleModalClose();
        setActionableData({});
        getMemories(1);
      } else {
        openSnackbar({ message: "Memory update failed! Please try again.", severity: 'error' });
      }
    } else {
      const response = await dispatch(createMemory({ ...data, projectId: projectId }));
      if (response?.payload && !response?.error) {
        openSnackbar({ message: "Memory created successfully.", severity: 'success' });
        handleModalClose();
        setActionableData({});
        setPageNumber(1);
        getMemories(1);
      } else {
        const errorMsg = response?.payload?.error;
        openSnackbar({ message: errorMsg || "Memory creation failed! Please try again.", severity: 'error' });
      }
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
    setActionType("");
  }

  const getMemories = async (page) => {
    try {
      await dispatch(getMemoryList({
        projectId: projectId,
        pageNumber: page || pageNumber,
        pageSize: 10
      }));
    } catch (error) {
      console.error('Error fetching memories:', error);
    }
  }

  return (
    <MainLayout
      isLoading={isLoading}
      sx={{ width: "calc(100% - 100px)" }}
      headerText="Project memory"
      subtitleText={`Showing ${total} project message(s)`}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={(e, value) => { setPageNumber(value); }}
      contentStyle={{ p: "8px 16px" }}
      rightSideContent={
        <CustomButton startIcon={<Add />} onClick={handleCreateMemory}>
          Add new memory
        </CustomButton>
      }
    >
      <MemoryList pageNumber={pageNumber} onAction={handleAction} />
      <MemoryModal
        detail={actionableData}
        isOpen={isModalOpen}
        actionType={actionType}
        onClose={handleModalClose}
        onConfirm={handleConfirm}
        projectId={projectId}
      />
    </MainLayout>
  )
}

export default MemoryPage;