import { apiAgent } from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateLoadError,
  setLoadingState,
  setSavingState,
  setAllVariableSets,
  updateSelectedVariableSetDetail,
} from './variableSetSlice';
import { VARIABLE_SETS_PAGE_SIZE } from '../../config/constants';

const createVariableSet = createAsyncThunk(
  'createVariableSet',
  async ({ projectId, title, isEnvironment }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'POST',
        url: '/api/v1/variable_sets/',
        data: {
          project_id: projectId,
          title: title,
          is_environment: isEnvironment,
        },
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getVariableSet = createAsyncThunk(
  'getVariableSet',
  async ({ variableSetId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'GET',
        url: `/api/v1/variable_sets/${variableSetId}`,
      });
      if (response.status === 200) {
        await dispatch(
          updateSelectedVariableSetDetail({
            variableSetDetail: response?.data,
          })
        );
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const updateVariableSet = createAsyncThunk(
  'updateVariableSet',
  async ({ variableSetId, data }, { dispatch }) => {
    await dispatch(setSavingState(true));
    try {
      const response = await apiAgent({
        method: 'PUT',
        url: `/api/v1/variable_sets/${variableSetId}`,
        data,
      });
      if (response.status === 200) {
        await dispatch(
          updateSelectedVariableSetDetail({
            variableSetDetail: response?.data,
          })
        );
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const deleteVariableSet = createAsyncThunk(
  'deleteVariableSet',
  async ({ variableSetId }, { dispatch, rejectWithValue }) => {
    await dispatch(setSavingState(true));
    try {
      const response = await apiAgent({
        method: 'DELETE',
        url: `/api/v1/variable_sets/${variableSetId}`,
      });
      if (response.status === 204) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const attachVariableSet = createAsyncThunk(
  'attachVariableSet',
  async (
    { variableSetId, resourceId, resourceType },
    { dispatch, rejectWithValue }
  ) => {
    await dispatch(setSavingState(true));
    try {
      const response = await apiAgent({
        method: 'POST',
        url: '/api/v1/variable_set_resources',
        data: {
          resource_id: resourceId,
          resource_type: resourceType,
          variable_set_id: variableSetId,
        },
      });
      if (response.status === 200) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const detachVariableSet = createAsyncThunk(
  'detachVariableSet',
  async ({ variableSetResourceId }, { dispatch, rejectWithValue }) => {
    await dispatch(setSavingState(true));
    try {
      const response = await apiAgent({
        method: 'DELETE',
        url: `/api/v1/variable_set_resources/${variableSetResourceId}`,
      });
      if (response.status === 200) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getAllVariableSets = createAsyncThunk(
  'getAllVariableSets',
  async ({ projectId, isEnvironment=false, skipEnvironment=false, pageNumber, pageSize=VARIABLE_SETS_PAGE_SIZE }, { dispatch }) => {
    const params = {
      project_id: projectId,
      page: pageNumber,
      page_size: pageSize,
      is_environment: isEnvironment,
    };
    // if (!skipEnvironment) {
    //   params.is_environment = isEnvironment;
    // }
    try {
      const response = await apiAgent({
        method: 'GET',
        url: '/api/v1/variable_sets',
        params: params,
      });
      if (response.status === 200) {
        if (isEnvironment) {
          await dispatch(setAllVariableSets(response.data.variable_sets));
        }
        return response.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const markVariableSetAsPrimary = createAsyncThunk(
  'markVariableSetAsPrimary',
  async (
    { projectId, variableSetId },
    { dispatch, rejectWithValue }
  ) => {
    await dispatch(setSavingState(true));
    try {
      const response = await apiAgent({
        method: 'POST',
        url: `/api/v1/projects/${projectId}/update_primary_environment`,
        data: {
          primary_environment_id: variableSetId
        },
      });
      if (response.status === 200) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export {
  createVariableSet,
  updateVariableSet,
  deleteVariableSet,
  getVariableSet,
  attachVariableSet,
  detachVariableSet,
  getAllVariableSets,
  markVariableSetAsPrimary
};
