import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem } from "@mui/material";

import coreUtils from "utils/coreUtils.js";
import { useSnackbar } from "contexts/CustomSnackbarContext";
import { getAllVariableSets } from "redux-store/variableSetReducers/variableSetActions";

const MemoryModal = ({ isOpen, onClose, actionType, onConfirm, detail = {}, projectId }) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const [message, setMessage] = useState(detail?.name || "");
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState(null);
  const [errors, setErrors] = useState({});
  const isLoading = useSelector(state => state.memory.isLoading);
  const [environments, setEnvironments] = useState([]);

  useEffect(() => {
    if (detail && detail.message) {
      setMessage(detail.message);
    } else {
      setMessage("");
    }
  }, [detail, isOpen]);

  useEffect(() => {
    fetchEnvironments();
  }, [projectId]);

  const fetchEnvironments = async () => {
    try {
      if (projectId) {
        const result = await dispatch(getAllVariableSets({
          projectId: projectId,
          isEnvironment: true
        })).unwrap();
        console.log('Result:', result);
        if (result?.variable_sets)
        {
          console.log('Environments:', result.variable_sets);
          setEnvironments(result.variable_sets);
          // Set default environment if available
          if (result.variable_sets.length > 0) {
            const defaultEnv = result.variable_sets.find(env => env.title === 'default') || result.variable_sets[0];
            setSelectedEnvironmentId(defaultEnv?.id);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching environments:', error);
      openSnackbar({
        message: "Failed to fetch environments. Please try again.",
        severity: "error",
      });
    }
  };

  const handleConfirmClick = (e) => {
    const errorsObj = validate();
    if (Object.keys(errorsObj).length > 0) {
      return;
    }
    onConfirm(actionType, {
      message: message?.trim(),
      environment_set_id: selectedEnvironmentId
    });
  }

  const handleClose = () => {
    setMessage("");
    setErrors({});
    onClose();
  }

  const handleChange = (value) => {
    setMessage(value);
    if (errors.name) validate();
  }

  const validate = () => {
    const errors = {};
    let nameError = coreUtils.isStringInvalidOrBlank(message) ? "Required" : "";
    if (nameError) errors.name = nameError;

    let environmentError = coreUtils.isStringInvalidOrBlank(selectedEnvironmentId) ? "Required" : "";
    if (environmentError) errors.environment = environmentError;

    setErrors(errors);
    return errors;
  }

  if (!isOpen) {
    return null;
  }

  let title = actionType === "create" ? "Add Memory" : "Edit Memory";
  let btnText = actionType === "create" ? "Create" : "Update";

  return (
    <Dialog maxWidth="sm" fullWidth onClose={(e) => { e.preventDefault(); }} open={isOpen}>
      <DialogTitle><h4>{title}</h4></DialogTitle>
      <DialogContent>
        <h6>Message</h6>
        <TextField
          value={message}
          size="small"
          fullWidth
          onChange={(e) => { handleChange(e.target.value) }}
          placeholder="Enter message"
          error={!!errors.name}
        />
        {errors.name && <span className="error">{errors.name}</span>}
      </DialogContent>

      {
        actionType != 'edit' &&
        <DialogContent>
          <h6>Environment</h6>
          <Select
            labelId="environment-select-label"
            id="environment-select"
            value={selectedEnvironmentId}
            label="Environment"
            onChange={(e) => {setSelectedEnvironmentId(e.target.value)}}
            sx={{ minWidth: 200 }}
          >
            {environments.map((env) => (
              <MenuItem key={env.id} value={env.id}>
                {env.title}
              </MenuItem>
            ))}
          </Select>
          {errors.environmentError && <span className="error">{errors.environmentError}</span>}
        </DialogContent>
      }

      <DialogActions sx={{ my: 2, p: "0px 24px" }}>
        <Button disabled={isLoading} onClick={handleClose} variant="outlined" color="error">
          Discard
        </Button>
        <Button disabled={isLoading} onClick={handleConfirmClick} variant="outlined" color="primary">
          {btnText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MemoryModal;