import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack, Typography, IconButton, Tooltip, MenuItem, MenuList } from "@mui/material";
import CustomTable from '../CustomTable';
import CustomMenu from '../CustomMenu';
import { MoreVert } from "@mui/icons-material";

const MemoryList = ({ pageNumber, onAction }) => {
  const isLoading = useSelector(state => state.memory.isLoading);
  const memoriesList = useSelector(state => state.memory.memoriesList || []);
  const [anchorEls, setAnchorEls] = useState({});

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const formatTimestamp = (timestamp) => {
    // Multiply by 1000 to convert to milliseconds if needed
    const date = new Date(timestamp * 1000);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    });
  };

  const getTableContent = () => {
    if (memoriesList?.length === 0 && isLoading) {
      return (
        <Box sx={{ width: "100%", textAlign: "center", padding: "50px 32px" }}>
          <h3>Loading messages...</h3>
        </Box>
      )
    }
    if (memoriesList?.length === 0) {
      return (
        <Box sx={{ width: "100%", textAlign: "center", padding: "50px 32px" }}>
          <h3>No messages found.</h3>
        </Box>
      )
    }

    return (
      <CustomTable
        headers={[
          {
            id: 'sr_no',
            type: 'text',
            text: 'Sr. No.',
            align: 'center',
          },
          {
            id: 'message',
            type: 'text',
            text: 'Message',
          },
          {
            id: 'environment_set',
            type: 'text',
            text: 'Environment',
          },
          {
            id: 'updated_at',
            type: 'text',
            text: 'Updated At',
          },
          {
            id: 'actions',
            type: 'text',
            text: 'Actions',
            align: 'center',
          }
        ]}
        rows={memoriesList.map(
          ({
            id,
            message,
            environment_set,
            updated_at,
            created_at
          }, index) => ({
            id,
            cells: [
              {
                id: 'sr-no-' + id,
                type: 'component',
                align: 'center',
                content: <Typography>{(pageNumber - 1) * 10 + index + 1}</Typography>,
              },
              {
                id: 'message-' + id,
                type: 'component',
                content: (
                  <Stack direction="column" className="title">
                    <Tooltip title={message} placement="top-start">
                      <Typography variant="body1" className="truncate-1-lines">{message}</Typography>
                    </Tooltip>
                  </Stack>
                ),
              },
              {
                id: 'environment_set-' + id,
                type: 'component',
                align: 'left',
                content: <Typography>{environment_set?.title}</Typography>,
              },
              {
                id: 'updated-at-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1">
                    {formatTimestamp(updated_at || created_at)}
                  </Typography>
                )
              },
              {
                id: 'actions-' + id,
                type: 'component',
                align: 'center',
                content: (
                  <>
                    <IconButton
                      id={`actions-btn-${id}`}
                      aria-label="actions"
                      onClick={(e) => handleClick(e, id)}
                    >
                      <MoreVert />
                    </IconButton>

                    <CustomMenu
                      labelledBy={'actions-btn-' + id}
                      anchorEl={anchorEls[id]}
                      onClose={() => handleClose(id)}
                    >
                      <MenuList sx={{ minWidth: '100px' }}>
                        <MenuItem onClick={() => { onAction("edit", { message, id, environment_set_id: environment_set?.id }); handleClose(id); }}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => { onAction("delete", { message, id }); handleClose(id); }}>
                          Delete
                        </MenuItem>
                      </MenuList>
                    </CustomMenu>
                  </>
                ),
                sx: { width: '50px' },
              }
            ],
          })
        )}
      />
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}>
        {getTableContent()}
      </Box>
    </Box>
  )
}

export default MemoryList;